<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Status</label>
            <b-select
              v-model="filter.status"
              :options="filterStatuses"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="showViewForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs>
            <b-tab
              title="Request Details"
              active
            >
              <b-form-group>
                <label for="created_at">Request Date</label>
                <b-input
                  id="created_at"
                  :value="paymentRequest.created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_status">Request Status</label>
                <b-input
                  id="request_status"
                  :value="paymentRequest.request_status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_payee">Request Payee</label>
                <b-input
                  id="request_payee"
                  :value="paymentRequest.request_payee"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_amount">Request Amount</label>
                <b-input
                  id="request_amount"
                  :value="paymentRequest.request_amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="budget_name">Budget Name</label>
                <b-input
                  id="budget_name"
                  :value="paymentRequest.budget_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="budget_actual_name">Budget Actual</label>
                <b-input
                  id="budget_actual_name"
                  :value="paymentRequest.budget_actual_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="item_name">Item Description</label>
                <b-input
                  id="item_name"
                  :value="paymentRequest.item_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_description">Request Description</label>
                <b-textarea
                  id="request_description"
                  :value="paymentRequest.request_description"
                  type="text"
                  :disabled="true"
                  rows="6"
                  max-rows="6"
                />
              </b-form-group>

              <b-form-group>
                <label for="payment_mode">Payment Mode</label>
                <b-input
                  id="payment_mode"
                  :value="paymentRequest.payment_mode"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_mode === 'Check' || paymentRequest.payment_mode === 'Cash'">
                <label for="payment_action">Payment Action</label>
                <b-input
                  id="payment_action"
                  :value="paymentRequest.payment_action"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank_account_number">Bank Account Number</label>
                <b-input
                  id="bank_account_number"
                  :value="paymentRequest.bank_account_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank_account_name">Bank Account Name</label>
                <b-input
                  id="bank_account_name"
                  :value="paymentRequest.bank_account_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank">Bank</label>
                <b-input
                  id="bank"
                  :value="paymentRequest.bank"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_one_name !== null">
                <label for="approval_one_name">Noted By</label>
                <b-input
                  id="approval_one_name"
                  :value="paymentRequest.approval_one_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_one_name !== null && paymentRequest.approval_one_status === 'Approved'">
                <label for="approval_one_status">Noted By Status</label>
                <b-input
                  id="approval_one_status"
                  :value="paymentRequest.approval_one_status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_one_name !== null && paymentRequest.approval_one_status === 'Approved'">
                <label for="approval_one_at">Noted At</label>
                <b-input
                  id="approval_one_at"
                  :value="paymentRequest.approval_one_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_one_name !== null && paymentRequest.approval_one_status === 'Approved'">
                <label for="approval_one_remarks">Noted By Remarks</label>
                <b-textarea
                  id="approval_one_remarks"
                  :value="paymentRequest.approval_one_remarks"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_two_name !== null">
                <label for="approval_two_name">Approved By</label>
                <b-input
                  id="approval_two_name"
                  :value="paymentRequest.approval_two_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <!-- <b-form-group v-if="paymentRequest.approval_two_name !== null && paymentRequest.approval_two_status === 'Approved'">
                <label for="approval_two_status">Approved By Status</label>
                <b-input
                  id="approval_two_status"
                  :value="paymentRequest.approval_two_status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_two_name !== null && paymentRequest.approval_two_status === 'Approved'">
                <label for="approval_two_at">Approved At</label>
                <b-input
                  id="approval_two_at"
                  :value="paymentRequest.approval_two_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_two_name !== null && paymentRequest.approval_two_status === 'Approved'">
                <label for="approval_two_remarks">Approved By Remarks</label>
                <b-textarea
                  id="approval_two_remarks"
                  :value="paymentRequest.approval_two_remarks"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group> -->
            </b-tab>

            <!-- <b-tab
              title="Tax Details"
            >
              <b-form-group>
                <label for="transaction_type">Transaction Type</label>
                <b-input
                  id="transaction_type"
                  :value="paymentRequest.transaction_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="payment_nature">Payment Nature</label>
                <b-input
                  id="payment_nature"
                  :value="paymentRequest.payment_nature"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="document_type">Document Type</label>
                <b-input
                  id="document_type"
                  :value="paymentRequest.document_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="receipt_type">Receipt Type</label>
                <b-input
                  id="receipt_type"
                  :value="paymentRequest.receipt_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="invoice_amount">Invoice Amount</label>
                <b-input
                  id="invoice_amount"
                  :value="paymentRequest.invoice_amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="amount">Taxable Amount</label>
                <b-input
                  id="amount"
                  :value="paymentRequest.amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="tax_with_held">Tax Withheld</label>
                <b-input
                  id="tax_with_held"
                  :value="paymentRequest.tax_with_held"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="pay_amount">Amount to Pay</label>
                <b-input
                  id="pay_amount"
                  :value="paymentRequest.pay_amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="supplier_type">Supplier Type</label>
                <b-input
                  id="supplier_type"
                  :value="paymentRequest.supplier_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="trade_name">Supplier Trade Name</label>
                <b-input
                  id="trade_name"
                  :value="paymentRequest.trade_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Corporation' && paymentRequest.transaction_type === 'With 2307'">
                <label for="company_name">Supplier Company Name</label>
                <b-input
                  id="company_name"
                  :value="paymentRequest.company_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                <label for="proprietor_first_name">Proprietor First Name</label>
                <b-input
                  id="proprietor_first_name"
                  :value="paymentRequest.proprietor_first_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                <label for="proprietor_middle_name">Proprietor Middle Name</label>
                <b-input
                  id="proprietor_middle_name"
                  :value="paymentRequest.proprietor_middle_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                <label for="proprietor_last_name">Proprietor Last Name</label>
                <b-input
                  id="proprietor_last_name"
                  :value="paymentRequest.proprietor_last_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="tin">Supplier Tin</label>
                <b-input
                  id="tin"
                  :value="paymentRequest.tin"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="address">Supplier Address</label>
                <b-textarea
                  id="address"
                  :value="paymentRequest.address"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>

            </b-tab> -->

            <b-tab
              v-if="paymentRequest.file_list.length>0"
              title="Attachments"
            >
              <b-button
                size="sm"
                variant="success"
                class="mb-2"
                @click="onPreviewAllAttachment"
              >
                Preview All
              </b-button>

              <b-table
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="paymentRequest.file_list"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      :disabled="isPreviewing"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>

            <b-tab
              v-if="paymentRequest.request_status === 'Cancelled'"
              title="Cancellation Details"
            >
              <b-form-group>
                <label for="user_cancelled_at">Cancelled At</label>
                <b-input
                  id="user_cancelled_at"
                  :value="paymentRequest.user_cancelled_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="user_remarks">Cancellation Reason</label>
                <b-textarea
                  id="user_remarks"
                  :value="paymentRequest.user_remarks"
                  type="text"
                  :disabled="true"
                  rows="6"
                  max-rows="6"
                />
              </b-form-group>
            </b-tab>

            <b-tab
              v-if="paymentRequest.request_status === 'Disapproved'"
              title="Disapproval Details"
            >
              <b-form-group>
                <label for="disapproved_at">Disapproved At</label>
                <b-input
                  id="disapproved_at"
                  :value="disapprovedAt"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="disapproved_by">Disapproved By</label>
                <b-input
                  id="disapproved_by"
                  :value="disapprovedBy"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="disapproved_reason">Disapproved Reason</label>
                <b-textarea
                  id="disapproved_reason"
                  :value="disapprovedReason"
                  type="text"
                  :disabled="true"
                  rows="6"
                  max-rows="6"
                />
              </b-form-group>
            </b-tab>

            <b-tab
              v-if="paymentRequest.approval_three_status === 'Approved'"
              title="Budget Approved"
            >
              <b-form-group>
                <label for="approval_three_remarks">Remarks</label>
                <b-textarea
                  id="approval_three_remarks"
                  :value="paymentRequest.approval_three_remarks"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>
            </b-tab>

          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          :disabled="isPreviewing"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- Modal Preview PDF -->
    <b-modal
      id="modal-preview-pdf"
      scrollable
      no-close-on-backdrop
      :title="`Preview PDF [${previewFilename}]`"
      size="lg"
    >
      <vue-pdf-embed :source="preview" />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- Modal Preview Image -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Image [${previewFilename}]`"
      size="lg"
    >
      <b-img
        fluid
        :src="preview"
      />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-all-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachments`"
      size="lg"
    >
      <div>
        <b-img
          v-for="attachment in allAttachments"
          :key="attachment"
          fluid
          :src="attachment"
        />
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { UserReportService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'UserRequestHistories',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Reports - Request Histories'
    }
  },

  components: {
    VuePdfEmbed
  },

  mixins: [formatter, url],

  data () {
    return {
      isPreviewing: false,
      preview: null,
      previewFilename: '',
      allAttachments: [],
      filter: {
        status: 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      filterStatuses: [
        { text: 'All', value: 'All' },
        { text: 'Disapproved', value: 'Disapproved' },
        { text: 'Cancelled', value: 'Cancelled' },
        { text: 'Completed', value: 'Completed' }
      ],
      paymentRequest: {
        id: 0,
        created_at: '',
        request_payee: '',
        request_amount: 0,
        transaction_type: '',
        request_status: '',
        budget_name: '',
        budget_actual_name: '',
        item_name: '',
        request_description: '',
        payment_mode: '',
        payment_action: '',
        bank_account_number: '',
        bank_account_name: '',
        bank: '',
        user_cancelled_at: '',
        user_remarks: '',
        approval_one_at: '',
        approval_one_status: '',
        approval_one_name: '',
        approval_one_remarks: '',
        approval_two_at: '',
        approval_two_status: '',
        approval_two_name: '',
        approval_two_remarks: '',
        approval_three_at: '',
        approval_three_status: '',
        approval_three_name: '',
        approval_three_remarks: '',
        supplier_type: '',
        trade_name: '',
        company_name: '',
        proprietor_first_name: '',
        proprietor_middle_name: '',
        proprietor_last_name: '',
        tin: '',
        address: '',
        payment_nature: '',
        document_type: '',
        receipt_type: '',
        invoice_amount: 0,
        amount: 0,
        tax_with_held: 0,
        pay_amount: 0,
        file_path: '',
        file_list: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'request date', sortable: true },
          { key: 'request_payee' },
          { key: 'request_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'request_status' },
          { key: 'document_number' },
          { key: 'disapproved_by' },
          { key: 'disapproved_at' },
          { key: 'cancelled_at' },
          { key: 'completed_at' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    disapprovedAt () {
      let str = ''

      if (this.paymentRequest.approval_one_status === 'Disapproved') {
        str = this.paymentRequest.approval_one_at
      }

      if (this.paymentRequest.approval_two_status === 'Disapproved') {
        str = this.paymentRequest.approval_two_at
      }

      if (this.paymentRequest.approval_three_status === 'Disapproved') {
        str = this.paymentRequest.approval_three_at
      }

      return str
    },

    disapprovedBy () {
      let str = ''

      if (this.paymentRequest.approval_one_status === 'Disapproved') {
        str = this.paymentRequest.approval_one_name
      }

      if (this.paymentRequest.approval_two_status === 'Disapproved') {
        str = this.paymentRequest.approval_two_name
      }

      if (this.paymentRequest.approval_three_status === 'Disapproved') {
        str = this.paymentRequest.approval_three_name
      }

      return str
    },

    disapprovedReason () {
      let str = ''

      if (this.paymentRequest.approval_one_status === 'Disapproved') {
        str = this.paymentRequest.approval_one_remarks
      }

      if (this.paymentRequest.approval_two_status === 'Disapproved') {
        str = this.paymentRequest.approval_two_remarks
      }

      if (this.paymentRequest.approval_three_status === 'Disapproved') {
        str = this.paymentRequest.approval_three_remarks
      }

      return str
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserReportService.getRequestHistories(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_status=${this.filter.status}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    showViewForm (item) {
      this.paymentRequest.id = item.id
      this.paymentRequest.created_at = this.dateTimeFormatter(item.created_at)
      this.paymentRequest.request_payee = item.request_payee
      this.paymentRequest.request_amount = this.numberFormatter(item.request_amount)
      this.paymentRequest.transaction_type = item.transaction_type
      this.paymentRequest.request_status = item.request_status
      this.paymentRequest.budget_name = item.budget_name
      this.paymentRequest.budget_actual_name = item.budget_actual_name
      this.paymentRequest.item_name = item.item_name
      this.paymentRequest.request_description = item.request_description
      this.paymentRequest.payment_mode = item.payment_mode
      this.paymentRequest.payment_action = item.payment_action
      this.paymentRequest.bank_account_number = item.bank_account_number
      this.paymentRequest.bank_account_name = item.bank_account_name
      this.paymentRequest.bank = item.bank
      this.paymentRequest.user_cancelled_at = item.user_cancelled_at
      this.paymentRequest.user_remarks = item.user_remarks
      this.paymentRequest.approval_one_at = item.approval_one_at
      this.paymentRequest.approval_one_status = item.approval_one_status
      this.paymentRequest.approval_one_name = item.approval_one_name
      this.paymentRequest.approval_one_remarks = item.approval_one_remarks
      this.paymentRequest.approval_two_at = item.approval_two_at
      this.paymentRequest.approval_two_status = item.approval_two_status
      this.paymentRequest.approval_two_name = item.approval_two_name
      this.paymentRequest.approval_two_remarks = item.approval_two_remarks
      this.paymentRequest.approval_three_at = item.approval_three_at
      this.paymentRequest.approval_three_status = item.approval_three_status
      this.paymentRequest.approval_three_name = item.approval_three_name
      this.paymentRequest.approval_three_remarks = item.approval_three_remarks
      this.paymentRequest.supplier_type = item.supplier_type
      this.paymentRequest.trade_name = item.trade_name
      this.paymentRequest.company_name = item.company_name
      this.paymentRequest.proprietor_first_name = item.proprietor_first_name
      this.paymentRequest.proprietor_middle_name = item.proprietor_middle_name
      this.paymentRequest.proprietor_last_name = item.proprietor_last_name
      this.paymentRequest.tin = item.tin
      this.paymentRequest.address = item.address
      this.paymentRequest.payment_nature = item.payment_nature
      this.paymentRequest.document_type = item.document_type
      this.paymentRequest.receipt_type = item.receipt_type
      this.paymentRequest.invoice_amount = this.numberFormatter(item.invoice_amount)
      this.paymentRequest.amount = this.numberFormatter(item.amount)
      this.paymentRequest.tax_with_held = this.numberFormatter(item.tax_with_held)
      this.paymentRequest.pay_amount = this.numberFormatter(item.pay_amount)
      this.paymentRequest.file_path = item.file_path
      this.paymentRequest.file_list = item.attachments
      this.$bvModal.show('modal-view-form')
    },

    async onPreviewAttachment (item) {
      // this.preview = null
      // this.isPreviewing = true

      // UserAttachmentService.retrieve(this.paymentRequest.id, item.file_hash_name).then(response => {
      //   this.isPreviewing = false
      //   if (item.file_extension === 'pdf') {
      //     this.previewFilename = item.file_original_name
      //     this.preview = `data:application/pdf;base64,${response.data}`
      //     this.$bvModal.show('modal-preview-pdf')
      //   } else {
      //     this.previewFilename = item.file_original_name
      //     this.preview = `data:image/${item.file_extension};base64,${response.data}`
      //     this.$bvModal.show('modal-preview-image')
      //   }
      // }).catch(() => {
      //   this.isPreviewing = false
      // })
      this.previewFilename = item.file_original_name
      this.preview = this.getImageUrl(`${this.paymentRequest.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    },

    onPreviewAllAttachment () {
      this.allAttachments = []

      this.paymentRequest.file_list.forEach(file => {
        this.allAttachments.push(this.getImageUrl(`${this.paymentRequest.file_path}/${file.file_hash_name}`))
      })

      this.$bvModal.show('modal-preview-all-image')
    },

    onFilterChange () {
      this.$refs.table.refresh()
    }
  }
}
</script>
